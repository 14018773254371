/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useRef } from 'react';
import { OrganizationContext } from '../contexts';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlag } from '@unleash/proxy-client-react';

declare global {
  interface Window {
    pendo: any;
  }
}

type UsePendoInitializer = {
  useFindQuery: any;
  product?: string;
};

export const usePendoInitializer = ({ useFindQuery, product = 'Catalyst' }: UsePendoInitializer) => {
  const { loading: isLoadingOrgs } = useContext(OrganizationContext);
  const { user: auth0User, isLoading: isLoadingAuth } = useAuth0();
  const pendoEnabled = useFlag('all.PendoEvaluation');
  const pendoInitialized = useRef(false);

  const { data: diagridUser, isLoading: isLoadingDiagridUser } = useFindQuery(
    { type: 'userSelf', normalization: { skip: true } },
    { skip: isLoadingAuth || !auth0User?.sub }
  );

  useEffect(() => {
    if (
      pendoEnabled &&
      !isLoadingOrgs &&
      !isLoadingDiagridUser &&
      diagridUser?.data?.email &&
      diagridUser?.data?.id &&
      diagridUser?.data?.name &&
      !pendoInitialized.current
    ) {
      try {
        window?.pendo?.initialize({
          visitor: {
            authId: diagridUser?.data?.id,
            id: diagridUser?.data?.email,
            email: diagridUser?.data?.email,
            name: diagridUser?.data?.name,
            role: JSON.stringify(diagridUser?.data?.roles),
            product,
          },
          account: {
            id: diagridUser?.data?.organization?.name,
            name: diagridUser?.data?.organization?.name,
            planLevel: diagridUser?.data?.organization?.products?.cra?.plan,
            orgId: diagridUser?.data?.organization?.id,
            creationDate: diagridUser?.data?.organization?.createdAt,
          },
        });
        if (!pendoInitialized.current) {
          pendoInitialized.current = true;
        }
        console.info('pendo initialized successfully');
      } catch (error) {
        console.error('Error initializing pendo', error);
      }
    }
  }, [diagridUser?.data, isLoadingDiagridUser, isLoadingOrgs, pendoEnabled, product]);
};
